body {
    overflow-x: hidden;
}

#skip a {
    position: absolute !important;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

#skip a:active,
#skip a:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
}

html.js #module-filter-submit.fixed {
    position: relative;
}

#module-filter-wrapper {

    ul.inline,
    ul.links.inline {
        font-size: rem-calc(14);
        display: block;
        margin: 0;
        padding: 0;
    }
}

a.tabledrag-handle .handle {
    width: rem-calc(29);
    height: rem-calc(29);
    background-position-y: rem-calc(13);
}

#admin-menu,
#admin-menu .dropdown {
    font-size: rem-calc(12);
}

#admin-menu {
    font-family: sans-serif;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

html.js fieldset.collapsed {
    height: auto;
    border-top: 1px solid rgba(10, 10, 10, 0.25);
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);

    legend {
        margin-bottom: 1rem;
        padding-right: 0.5rem;
    }
}

html.js fieldset.collapsible legend {
    margin-bottom: 0;
    padding-right: 0.5rem;
}

html.js fieldset.collapsed legend:before {
    transform: rotate(-90deg);
}

html.js fieldset.collapsible legend:before {
    content: "\25bc";
    margin-right: rem-calc(10);
    float: left;
    position: relative;
    font-size: rem-calc(8);
}

ul.pager {
    @include pagination-container;
    text-align: center;

    .pager-current {
        @include pagination-item-current;
    }
}

table {
    font-size: rem-calc(14);

    select,
    input {
        margin-bottom: 0;
    }

    tr.info {
        background-color: #1C6DCB;
        color: white;

        a {
            color: white;
            text-decoration: underline;
        }
    }

    tr.ok {
        background-color: $success-color;
        color: white;

        a {
            color: white;
            text-decoration: underline;
        }
    }

    tr.error {
        background-color: $alert-color;
        color: white;

        a {
            color: white;
            text-decoration: underline;
        }
    }
}

.callout {
    font-size: rem-calc(14);
}

.callout>ul {
    list-style-type: none;
    margin-left: 0;
}

.callout.info {
    background-color: #1C6DCB;
}

.callout.success,
.callout.alert,
.callout.warning,
.callout.info,
    {
    color: white;

    a {
        color: white;
        text-decoration: underline;
    }
}

.callout.success,
.callout.alert,
.callout.warning,
.callout.info,
    {
    .krumo-root {
        color: $black;

        a {
            color: $black;
        }
    }
}

.action-links {
    list-style-type: none;
    margin-left: 0;
    @include button-group('.button', 1);

    .button {
        margin-bottom: 0;
    }
}

.second-tabs ul {
    margin-left: 0;
}

p:last-child {
    margin-bottom: 0;
}

.blocs .champ.texte p:last-child {
    margin-bottom: $paragraph-margin-bottom;
}

a.tabledrag-toggle-weight,
.element-invisible {
    display: none;
}

a.fieldset-title {
    color: $white;
}



#boutonoffcanvasgauche {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;

    .button {
        margin: 0;
    }
}


.image-widget.form-managed-file {
    display: flex;
    justify-content: space-between;

    .image-widget-data .form-group {
        font-size: .5rem;

        a {
            text-decoration: underline;
            margin-right: 1rem;
        }
    }

    .file-size {
        font-style: italic;
    }
}


fieldset {
    border: 1px solid $black;
    padding: 0 .5rem;
    margin-bottom: 1.5rem;

    .fieldset-description {
        color: gray;
        font-size: .75rem;
    }

    legend>.label {
        padding-left: .5rem;
        padding-right: .5rem;
        font-style: italic;
        font-weight: normal;
    }
}
