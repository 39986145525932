.grid-container.full {
    @include breakpoint(xlarge) {
        max-width: rem-calc(1360);
    }
}

.description {
    font-size: $label-font-size;
    margin-bottom: $form-spacing / 2;
}

.button,
.button-group .button {
    font-size: $label-font-size;
}

.bloc-megamenu ul.menu {
    @include menu-direction(vertical);
}

.vuesaison .view-content .group-cells {
    @include xy-gutters(0, margin, right left, false);
    @include xy-gutters(1rem, padding, right left, false);
}

a.teaser.evenement.affiche {
    @include xy-gutters(2rem, margin, bottom, false);
}

.blocsgrid>.cell {
    @include xy-gutters(1rem, padding, right left, false);
    @include xy-gutters(2rem, margin, bottom, false);
}

.blocsgrid {
    @include xy-gutters(1rem, margin, right left, true);

    @include breakpoint(medium) {
        @include xy-gutters(1rem, margin, right left, false);
    }

    @include breakpoint(xlarge) {
        @include xy-gutters(1rem, margin, right left, true);
    }
}

// .blocs .champ.texte a:hover,
// .blocs .champ.texte a:focus {
//     color: scale-color($jaune, $lightness: -40%);
// }
.node-event.view-mode-affiche {
    @include breakpoint(medium) {
        @include xy-gutters(2rem, padding, right left, false);
    }

    @include breakpoint(xlarge) {
        @include xy-gutters(0, padding, right left, false);
    }
}

.vuesaison .view-content .group-cells>.cell,
.champ.references>.cell {
    @include xy-gutters(1rem, padding, right left, false);
    @include xy-gutters(2rem, padding, bottom, false);
}

.champ.references {
    @include xy-gutters(1rem, padding, top right left, false);
}

a.teaser.evenement>.groupchamp,
a.teaser.page .groupchamp,
a.teaser.evenement.affiche .groupchamp,
.blocsgrid .tuile .champ.texte,
.addpadding,
.addpaddingleftright {
    @include xy-gutters(1rem, padding, top bottom right left, false);
}

.bloc-megamenu.cell {
    @include xy-gutters(map-get($grid-padding-gutters, small), padding, top bottom, false);

    @include breakpoint(medium) {
        @include xy-gutters(map-get($grid-padding-gutters, medium), padding, top bottom, false);
    }

    @include breakpoint(large) {
        @include xy-gutters(map-get($grid-padding-gutters, large), padding, top bottom, false);
    }

    @include breakpoint(xlarge) {
        @include xy-gutters(map-get($grid-padding-gutters, xlarge), padding, top bottom, false);
    }

    @include breakpoint(xxlarge) {
        @include xy-gutters(map-get($grid-padding-gutters, xxlarge), padding, top bottom, false);
    }
}

.lignemois.non-dfini {
    @include xy-gutters(2rem, padding, top, false);
}

.lignemois h3,
.node-type-webform .webform-client-form>div fieldset {
    @include xy-gutters($grid-padding-gutters, padding, right left, false);
    padding-top: map-get($grid-padding-gutters, small);

    @include breakpoint(medium) {
        padding-top: map-get($grid-padding-gutters, medium);
    }

    @include breakpoint(large) {
        padding-top: map-get($grid-padding-gutters, large);
    }

    @include breakpoint(xlarge) {
        padding-top: map-get($grid-padding-gutters, xlarge);
    }

    @include breakpoint(xxlarge) {
        padding-top: map-get($grid-padding-gutters, xxlarge);
    }
}

.node-type-webform .webform-client-form>div {
    @include breakpoint(large) {
        @include xy-gutters($grid-padding-gutters, margin, right left, true);
    }
}

.node-type-webform .webform-client-form>div fieldset {
    @include xy-gutters($grid-padding-gutters, padding, right left, false);
}

.listemois .view-content,
.listemois .view-footer {
    @include xy-gutters($grid-padding-gutters, padding, top bottom right left, false);
}

.blocs .img-gauche-texte-droite .champ.image .item,
.blocs .texte-gauche-img-droite .champ.image .item {
    margin-bottom: map-get($grid-padding-gutters, small);

    @include breakpoint(medium) {
        margin-bottom: map-get($grid-padding-gutters, medium);
    }

    @include breakpoint(large) {
        margin-bottom: map-get($grid-padding-gutters, large);
    }

    @include breakpoint(xlarge) {
        margin-bottom: map-get($grid-padding-gutters, xlarge);
    }

    @include breakpoint(xxlarge) {
        margin-bottom: map-get($grid-padding-gutters, xxlarge);
    }
}

.page-node-58 .champ.portrait {
    width: 100%;

    @include breakpoint(large) {
        width: 50%;
        @include xy-gutters($grid-padding-gutters, margin, bottom right, false);
    }
}

.blocs .champ.image .caption {
    @include xy-gutters($grid-padding-gutters, padding, right left, false);
}

#header-spectacle,
#texte-spectacle,
#dates-spectacles,
#other-spectacles,
.champ.pager ul.custom-pager,
.champ.references {
    @include xy-gutters($grid-padding-gutters, padding, top bottom, false);
}

.medias .slick-slide .champ.video,
.medias .slick-slide .player {
    @include responsive-embed(3 by 2);
}

.blocs .champ.fichier {
    text-align: center;
}

.blocs .champ.fichier .file a {
    @include button(false, $black, auto, $white, solid);
}